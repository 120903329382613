import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BackToTop from "../components/back-to-top"
import { Container, Row, Col } from "reactstrap"
import { LazyLoadImage } from "react-lazy-load-image-component"

const PaymentOptions = ({ location }) => {
  // console.log(location)
  return (
    <Layout>
      <Seo title="Payment Options" />
      <div className="hero-adjustment pb-0">
        <div className="container">
          <div className="hero-content-wrapper">
            <div className="row no-gutters">
              <div className="col-12 col-md-7">
                <div className="hero-dog-bg">
                  <div className="dog-bg"></div>
                </div>
              </div>
              <div className="col-12 col-md-5">
                <div className="hero-video">
                  <div>
                    <div>
                      <div className="video-modal-btn no-video">
                        <LazyLoadImage
                          effect="blur"
                          src="/assets/img/tplo-video.jpg"
                          alt="video"
                          className="video-img img-fluid v-h"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-only-heading inner-page-title text-right arthroscopy-hero noVideoInner">
              <h1>Payment Options</h1>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="about-wrapper py-5"> */}
      <Container className="acl-container my-5">
        <Row className="border-none">
          <Col md={12}>
            <p>
              Colorado Canine Orthopedics and Rehab accepts Visa, MasterCard and
              Discover credit cards as well as cash and personal checks.
            </p>
            <h3>Financing Options</h3>
            <p>
              <strong>
                Wells Fargo Health Advantage® Credit Card - No interest if paid
                in full within 12 months. Interest will be charged to your
                account from the purchase date if the purchase balance is not
                paid infull within the promotional period.*
              </strong>
            </p>
            <p>
              *The Wells Fargo Health Advantage® credit card is issued by Wells
              Fargo Bank, N.A. Special terms apply to qualifying purchases with
              approved credit. Minimum monthly payments are required during the
              promotional (special terms) period. Interest will be charged to
              your account from the purchase date at the APR for Purchases if
              the purchase balance is not paid in full within the promotional
              (special terms) period. Paying only the minimum monthly payment
              will not pay off the purchase balance before the end of the
              promotional period. For new accounts, the APR for Purchases is
              12.99%. If you are charged interest in any billing cycle, the
              minimum interest charge will be $1.00. This information is
              accurate as of 10/01/2019 and is subject to change. For current
              information, call us at{" "}
              <a href="tel:18004315921" className="tel-cta">
                1-800-431-5921
              </a>
              .
            </p>
            <a
              href="https://retailservices.wellsfargo.com/pl/2169230646"
              target="_blank"
              className="tel-cta"
              rel="noreferrer"
            >
              <LazyLoadImage
                effect="blur"
                src="/assets/img/WFHA_180x150.png"
                alt="WFHA"
                className="img-fluid mb-4"
              />
            </a>
            <hr />
            <p>
              <strong>Flexible Financing with the CareCredit credit card</strong>
            </p>
            <p>CareCredit gives you a convenient, flexible way to pay for care for the whole family—including pets! With no interest if paid in full within 6 months on qualifying purchases of $200 or more, you can pay over time with convenient monthly payments.* Interest will be charged to your account from the purchase date if the promotional balance is not paid in full within the promotional period. Minimum monthly payments required.</p>
            <p>See if you prequalify with no impact to your credit score.</p>
            <a
              href="https://www.carecredit.com/go/777RMH/"
              target="_blank"
              className="tel-cta"
              rel="noreferrer"
            >
              <LazyLoadImage
                effect="blur"
                src="/assets/img/carecredit_button_applynow.png"
                alt="WFHA"
                className="img-fluid mb-4"
              />
            </a>
            <p><small>*No interest will be charged on the promo balance if you pay it off, in full, within the promo period. If you do not, interest will be charged on the promo balance from the purchase date. The required minimum monthly payments may or may not pay off the promo balance before the end of the promo period, depending on purchase amount, promo length and payment allocation. Regular account terms apply to non-promo purchases and, after promo period ends, to the promo balance. For new accounts: Purchase APR (interest rate) is 29.99%; Minimum Interest Charge is $2. Existing cardholders: See your credit card agreement terms. Subject to credit approval.</small></p>
          </Col>
        </Row>
      </Container>
      <BackToTop />
      {/* </div> */}
    </Layout>
  )
}

export default PaymentOptions
